@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "San Francisco";
  src: url("../fonts/SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: "San Francisco", sans-serif;
  line-height: 0.5;
  background-color: #171717;
  background-image: linear-gradient(
      transparent 99%, rgba(255, 255, 255, 0.03) 99%
    ),
    linear-gradient(
      90deg, transparent 99%, rgba(255, 255, 255, 0.03) 99%
    );
  background-size: 90px 90px;
  @apply text-white;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

nav ul {
  list-style: none;
}

a {
  text-decoration: none;
  @apply text-gray-400;
}

a.active {
  @apply text-white;
}

a:hover {
  @apply text-white;
  text-decoration: underline;
  transition: all 0.1s;
}

.macbook {
  width: 68.125em;
}

.display {
  background-color: #000;
  overflow: hidden;
  width: 55.375em;
  height: 36em;
  margin: auto;
  border-top-right-radius: 1.7em;
  border-top-left-radius: 1.7em;
  position: relative;
}

.display:before {
  content: "";
  width: 6em;
  height: 1.1em;
  background: #000;
  position: absolute;
  top: 0.5em;
  left: 0;
  right: 0;
  margin: auto;
  border-bottom-left-radius: 0.4em;
  border-bottom-right-radius: 0.4em;
  z-index: 999;
}

.display:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 18.75em solid transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 150;
}

.screen {
  z-index: 100;
  top: 1.5%;
  left: 1%;
  border-top-right-radius: 1.2em;
  border-top-left-radius: 1.2em;
  width: 98%;
  height: 96%;
  margin: auto;
  position: absolute;
  background: url('./background.webp') no-repeat;
  background-size: cover;
}

.base {
  height: 1.25em;
  width: 68.125em;
  background-color: #eff0f4;
  position: relative;
}

.base:after,
.base:before {
  width: 4em;
  height: 100%;
  content: "";
  top: 0;
  right: 0;
  position: absolute;
  background: linear-gradient(
    to right,
    rgba(226, 228, 227, 0.35) 0%,
    rgba(140, 141, 145, 0.35) 51%,
    rgba(226, 228, 227, 0.35) 99%,
    rgba(226, 228, 227, 0.35) 100%
  );
}

.base:before {
  left: 0;
}

.bottom {
  height: 0.55em;
  width: 68.125em;
  margin: auto;
  background: linear-gradient(
    to bottom,
    rgba(153, 154, 158, 0.75) 30%,
    rgba(44, 45, 47, 0.6) 90%
  );
  border-bottom-right-radius: 8em;
  border-bottom-left-radius: 8em;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.05), 0 15px 12px rgba(0, 0, 0, 0.05);
}

.indent {
  height: 0.75em;
  width: 10em;
  margin: auto;
  background: linear-gradient(
    to bottom,
    rgba(153, 154, 158, 0.75) 30%,
    rgba(44, 45, 47, 0.6) 90%
  );
  border-bottom-right-radius: 8em;
  border-bottom-left-radius: 8em;
}

main:focus {
  outline: none;
}

nav > a:first-of-type {
  display: block;
}

.content {
  scrollbar-width: 16px;
  scrollbar-color: #1E1E1E #1E1E1E;
}

.content::-webkit-scrollbar {
  width: 16px;
  overflow: hidden;
}

.content::-webkit-scrollbar-track {
  background: #1E1E1E;
}

.content::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border: 0px solid transparent;
}

:root {
  --padding-x: 1.5rem;
  --padding-y: 1rem;
  --themes-width: 2rem;
  --navbar-min-height: 2.25rem;
}

h1,
h2,
h3,
a {
  font: inherit;
}

.site-wrap {
  width: 40vw;
  height: 40vh;
  background-color: #272B33;
  color: var(--text-color);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  position: relative;
  overflow: hidden;
  z-index: 9999;
}

.window__bar {
  background-color: #272B33;
  padding-right: 10rem;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}

.content {
  padding: var(--padding-y) var(--padding-x);
  margin-left: 1.5rem;
  margin-top: 5px;
  overflow: auto;
  background-color: #1E1E1E;
  @apply text-white;
  height: 100%;
  position: relative;
}
.content > * {
  max-width: min(calc(100% - var(--padding-x) * 4), 100ch);
}